import { Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';

import { UIImage } from '@ui';

import { Props } from './index.type';

import './style.scss';

const BrandDetailDiscription = ({ loading, brand }: Props) => {
  return (
    <Spin size="large" spinning={loading}>
      <div className="card-brands-info-brand">
        <div className="card-brands-info-brand-header">
          <div>
            <Title level={5}>Logo</Title>
            <div
            // style={{
            //   ...(brand?.background?.path && {
            //     background: `url(${brand?.background?.path}) no-repeat center center`,
            //     backgroundSize: 'cover',
            //   }),
            // }}
            >
              <UIImage path={brand?.logo?.path || 'error'} />
            </div>
          </div>
          {/* <div>
            <Link onClick={onEditClickHandler}>Edit</Link>
          </div> */}
        </div>
        <div className="card-brands-info-brand-line"></div>
        <div className="card-brands-info-brand-content">
          <Title level={5}>Name of the brands</Title>
          <Text>{brand?.name}</Text>
        </div>
        <div className="card-brands-info-brand-line"></div>
        <div className="card-brands-info-brand-content">
          <Title level={5}>Description</Title>
          <Text>{brand?.description}</Text>
        </div>
      </div>
    </Spin>
  );
};

export default BrandDetailDiscription;
