/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable indent */
import { useEffect, useState } from 'react';

import { Col, Form, Input, InputNumber, Row, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useSelector } from 'react-redux';

import { en } from '@i18n';
import { LocalStorage } from '@services/storage';
import {
  clearListBrands,
  getListBrandRequest,
  getOneBrandRequest,
} from '@store/Brand';
import {
  clearListCategory,
  getListCategoryRequest,
  getOneCategoryRequest,
} from '@store/Category';
import { clearListEffect, getListEffectRequest } from '@store/Effect';
import { RootState, useAppDispatch } from '@store/index';
import { UICalendar, UISelectFormItem } from '@ui';
import { DiscountUnit, OptionSelect } from '@utils';

import { WidgetModalUIImage } from '../../ui';
import { INITIAL_VALUES } from './constant';
import { FormValues, Props } from './index.type';

const WidgetModalProductForm = ({
  onFormSubmit,
  form,
  product,
  onUploadImage,
  image,
  promotionImage,
  onRemoveImage,
  isCreate,
}: Props) => {
  const dispatch = useAppDispatch();
  const productEffect: OptionSelect[] | null =
    LocalStorage.get('productEffect');

  const {
    brandList,
    loading: loadingBrand,
    meta: metaBrand,
  } = useSelector((state: RootState) => state.brand);
  const {
    categoryList,
    loading: loadingCategory,
    meta: metaCategory,
  } = useSelector((state: RootState) => state.category);
  const {
    effectList,
    loading: loadingEffect,
    meta: metaEffect,
  } = useSelector((state: RootState) => state.effect);

  const price = Form.useWatch('price', form);
  const categoryId = Form.useWatch('categoryId', form);
  const brandId = Form.useWatch('brandId', form);
  const isPromotion = Form.useWatch('isPromotion', form);

  const totalPrice = Form.useWatch('totalPrice', form);
  const discountPrice = Form.useWatch('discountPrice', form);
  const discountUnitValue =
    Form.useWatch(['discount', 'discountUnit'], form) || DiscountUnit.PERCENT;
  const isPercent = discountUnitValue === DiscountUnit.PERCENT;

  const [isDiscount, setIsDiscount] = useState(false);
  const [percent, setPercent] = useState(1);
  const [timeActive, setTimeActive] = useState<[string, string] | []>([]);
  const [effects, setEffects] = useState<OptionSelect[] | null | undefined>(
    productEffect,
  );

  const setValueAll = (val: OptionSelect[]) => {
    LocalStorage.set('productEffect', val);
    setEffects(val);
  };

  const setFormPercent = (price: string | number, percent: string | number) => {
    const newDiscountPrice = +(+price * (+(percent || 0) / 100)).toFixed(2);

    setPercent(+percent);
    form.setFieldValue('discountPrice', +newDiscountPrice.toFixed(2));
    form.setFieldValue('totalPrice', +(+price - newDiscountPrice).toFixed(2));
  };

  const setFormTotal = (price: string | number, total: string | number) => {
    form.setFieldValue('discountPrice', +(+total).toFixed(2));
    form.setFieldValue('totalPrice', +(+price - +total).toFixed(2));
    setPercent(+(+total / (+price / 100)).toFixed(2));
  };

  useEffect(() => {
    if (product) {
      form.setFieldsValue(product);
      setPercent(
        +(100 - +product.discountPrice / (+product.price / 100)).toFixed(2),
      );
      if (!product.discountPrice && product.discount?.discountValue) {
        const dp = product.discount?.discountValue;

        if (product.discount?.discountUnit === DiscountUnit.PERCENT) {
          setFormPercent(product.price, dp);
        } else {
          setFormTotal(product.price, dp);
        }
      } else {
        setPercent(
          +(+product.discountPrice / (+product.price / 100)).toFixed(2),
        );
      }
      setIsDiscount(!!product?.discount);
      if (product.discount?.validFrom && product.discount?.validUntil) {
        setTimeActive([
          product.discount.validFrom,
          product.discount.validUntil,
        ]);
      }
      setValueAll(
        product?.effects?.map((item) => ({
          label: item.name,
          value: item.id,
        })) || [],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, form]);

  const handlerFinish = (values: FormValues) => {
    const {
      packsInStock,
      gramsInStock,
      gramsPerPack,
      discountPrice: valDiscountPrice,
      totalPrice: valTotalPrice,
      ...val
    } = values;

    onFormSubmit({
      ...val,
      image,
      ...((!product?.gramsPerPack ||
        (product?.gramsPerPack &&
          (gramsPerPack || gramsPerPack === 0) &&
          +gramsPerPack !== product?.gramsPerPack)) && { gramsPerPack }),
      ...((!product?.gramsInStock ||
        (product?.gramsInStock &&
          (gramsInStock || gramsInStock === 0) &&
          +gramsInStock !== product?.gramsInStock)) && { gramsInStock }),
      discount: isDiscount
        ? {
            discountUnit: discountUnitValue || DiscountUnit.PERCENT,
            discountValue: isPercent ? percent : valDiscountPrice,
            ...(timeActive.length && {
              validFrom: timeActive[0],
              validUntil: timeActive[1],
            }),
          }
        : null,
      promotionImage: isPromotion ? promotionImage : null,
      effects: effects?.map((effect) => ({
        id: +effect.value,
        name: effect.label,
      })),
    });
  };

  const formCheckbox = {
    labelCol: { span: 'auto' },
    wrapperCol: { span: 'auto' },
  };

  const discountWrap = {
    labelCol: { span: 14 },
    wrapperCol: { span: 10 },
  };

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      requiredMark={false}
      size="large"
      initialValues={INITIAL_VALUES}
      onFinish={handlerFinish}
      autoComplete="off"
      validateMessages={en.validation}
    >
      <WidgetModalUIImage
        name="image"
        view="image"
        path={image?.path || 'error'}
        onUploadImage={onUploadImage}
        onRemoveImage={onRemoveImage}
      />
      <Form.Item
        name="name"
        label="Name of the product"
        required
        rules={[{ required: true, whitespace: true }]}
      >
        <Input placeholder="Enter the product" />
      </Form.Item>
      <Form.Item
        label="Price"
        name="price"
        required
        rules={[{ required: true, min: 0.01, type: 'number' }]}
      >
        <InputNumber
          min={0}
          type="number"
          precision={2}
          {...(+price < 0.01 && { status: 'error' })}
          placeholder="Price"
        />
      </Form.Item>
      <Form.Item label="Grams per pack" name="gramsPerPack">
        <InputNumber min={0} type="number" placeholder="Grams per pack" />
      </Form.Item>
      <Form.Item label="Total grams" name="gramsInStock">
        <InputNumber
          // disabled={true}
          min={0}
          type="number"
          placeholder="Total grams"
        />
      </Form.Item>
      <Form.Item label="Units in stock" name="packsInStock">
        <InputNumber
          disabled={true}
          min={0}
          type="number"
          placeholder="Units in stock"
        />
      </Form.Item>
      <UISelectFormItem
        label="Brand"
        name="brandId"
        rules={[{ required: true }]}
        required={!!brandId}
        options={brandList || []}
        getOption={(id) =>
          id &&
          dispatch(
            getOneBrandRequest({
              id: +id,
              toList: true,
            }),
          )
        }
        valueId={brandId}
        getOptions={(query) =>
          dispatch(getListBrandRequest(`${query}&filter[isActive]=true`))
        }
        clearOptions={() => dispatch(clearListBrands())}
        meta={metaBrand}
        loading={loadingBrand}
      />
      <UISelectFormItem
        label="Category"
        name="categoryId"
        options={categoryList || []}
        getOption={(id) =>
          id &&
          dispatch(
            getOneCategoryRequest({
              id: +id,
              toList: true,
            }),
          )
        }
        valueId={categoryId}
        getOptions={(query) =>
          dispatch(getListCategoryRequest(`${query}&filter[isActive]=true`))
        }
        clearOptions={() => dispatch(clearListCategory())}
        meta={metaCategory}
        loading={loadingCategory}
      />
      <UISelectFormItem
        label="Effect"
        value={effects as OptionSelect[]}
        onChange={(ids, values) => {
          const newValue = (ids as number[])?.map((id) =>
            [
              ...(values as OptionSelect[]),
              ...((effects || []).map((i) => i) || []),
            ].find((e) => +e.value === +id),
          );

          setValueAll(newValue as OptionSelect[]);
        }}
        options={effectList || []}
        getOptions={(query) =>
          dispatch(getListEffectRequest(`${query}&filter[isActive]=true`))
        }
        clearOptions={() => dispatch(clearListEffect())}
        meta={metaEffect}
        loading={loadingEffect}
        mode="multiple"
      />
      {(product || isCreate) && (
        <>
          <Row justify="space-between">
            <Form.Item {...formCheckbox} label="Visible" name="isActive">
              <Switch defaultChecked={product?.isActive} />
            </Form.Item>
            <Form.Item {...formCheckbox} label="Is fastest" name="isFastest">
              <Switch defaultChecked={product?.isFastest} />
            </Form.Item>
            <Form.Item
              {...formCheckbox}
              label="Is promotion"
              name="isPromotion"
            >
              <Switch defaultChecked={product?.isPromotion} />
            </Form.Item>
          </Row>
          <Row justify="space-between">
            <Form.Item
              {...formCheckbox}
              label="Is today deals"
              name="isTodayDeals"
            >
              <Switch defaultChecked={product?.isTodayDeals} />
            </Form.Item>
            <Form.Item
              {...formCheckbox}
              label="Is most popular"
              name="isMostPopular"
            >
              <Switch defaultChecked={product?.isMostPopular} />
            </Form.Item>
            <Form.Item {...formCheckbox} label="Is discount">
              <Switch
                checked={isDiscount}
                onChange={(item) => setIsDiscount(item)}
              />
            </Form.Item>
          </Row>
        </>
      )}
      {isPromotion && (
        <WidgetModalUIImage
          name="promotionImage"
          view="image"
          path={promotionImage?.path || 'error'}
          onUploadImage={onUploadImage}
          onRemoveImage={onRemoveImage}
        />
      )}
      <Form.Item label="Description" name="description">
        <TextArea showCount rows={4} placeholder="Enter the description" />
      </Form.Item>
      {isDiscount && (
        <>
          <Row justify="center">
            <Col span={8}>
              <Form.Item
                label="Percentage"
                {...discountWrap}
                required
                rules={[{ required: true, min: 0, max: 100, type: 'number' }]}
              >
                <InputNumber
                  disabled={!isPercent}
                  type="number"
                  value={percent}
                  onChange={(item) => {
                    if (item) {
                      setFormPercent(price, item);
                    }
                  }}
                  {...(percent &&
                    (+percent < 0.01 || +percent > 100) && { status: 'error' })}
                  min={0}
                  max={100}
                  placeholder="Percentage"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Amount"
                name="discountPrice"
                {...discountWrap}
                required
                rules={[
                  { required: true, min: 0.01, max: +price, type: 'number' },
                ]}
              >
                <InputNumber
                  disabled={isPercent}
                  {...(discountPrice &&
                    (+discountPrice < 0.01 || +discountPrice > +price) && {
                      status: 'error',
                    })}
                  type="number"
                  max={+price}
                  min={0.01}
                  value={discountPrice || 0}
                  onChange={(item) => {
                    if (item) {
                      setFormTotal(price, item);
                    }
                  }}
                  placeholder="Amount"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...discountWrap}
                label="Is percent"
                name={['discount', 'discountUnit']}
              >
                <Switch
                  onChange={(item) => {
                    form.setFieldValue(
                      ['discount', 'discountUnit'],
                      item ? DiscountUnit.PERCENT : DiscountUnit.CURRENCY,
                    );
                  }}
                  checked={isPercent}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center">
            <Col span={16}>
              <Form.Item
                required
                rules={[{ required: true }]}
                label="Discount life time"
              >
                <UICalendar
                  value={timeActive}
                  {...(timeActive[1] &&
                    new Date(timeActive[1]) < new Date() && {
                      status: 'error',
                    })}
                  onChange={(_, i) => setTimeActive(i)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Total price"
                name="totalPrice"
                {...discountWrap}
              >
                <InputNumber
                  disabled={true}
                  type="number"
                  value={totalPrice}
                  placeholder="Total price"
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

export default WidgetModalProductForm;
