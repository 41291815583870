/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import { FullOrder } from '@entities/Order';
import { FullProduct } from '@entities/Product';
import { UICellFullName, UICellStatus, UICellTooltip, UITag } from '@ui';
import {
  COLUMN_WIDTH_NAME,
  COLUMN_WIDTH_NUMBER,
  LINK_PRODUCT,
  ProcessStatus,
  StringHelper,
  processStatusOptions,
} from '@utils';

export const infoList = (order: FullOrder | null) => [
  {
    name: 'Address',
    value: order?.address?.formattedAddress || 'not specified',
  },
  {
    name: 'Customer ID',
    className: 'wordBreakAll',
    value: order?.user.driverLicenseNumber || 'no license',
  },
  // {
  //   name: 'User',
  //   value: UICellLink({
  //     value: order?.user?.profile?.fullName || 'no name',
  //     link: `/${LINK_USERS}/${order?.user?.id}`,
  //     className: 'p-0',
  //   }),
  // },
  {
    name: 'Status',
    value: UITag({
      value:
        order?.status === ProcessStatus.CANCELED
          ? order.canceledByWhom || order.status
          : order?.status,
      values: processStatusOptions,
    }),
  },
  // {
  //   name: 'Payment method',
  //   value: order?.paymentMethod,
  // },
  {
    name: 'Shipping method',
    value: order?.shippingMethod,
  },
  {
    name: 'Payment method',
    className: 'wordBreakAll',
    value: order?.paymentMethod,
  },
  {
    name: 'Transaction',
    value:
      order?.paymentMethod === 'cash'
        ? '-'
        : UICellStatus({
            value: !!order?.paymentTransaction,
            text: order?.paymentTransaction ? 'paid for' : 'not paid',
          }),
  },
  {
    name: 'Price',
    value: StringHelper.toMoney(order?.totalPrice, 2),
  },
  {
    name: 'Tips',
    value: StringHelper.toMoney(order?.tips, 2),
  },
  {
    name: 'Taxes',
    value: StringHelper.toMoney(
      (order?.exciseTax || 0) +
        (order?.salesTax || 0) +
        (order?.businessTax || 0),
      2,
    ),
  },
  {
    name: 'Excise Tax',
    value: StringHelper.toMoney(order?.exciseTax, 2),
  },
  {
    name: 'Sales tax',
    value: StringHelper.toMoney(order?.salesTax, 2),
  },
  {
    name: 'Business tax',
    value: StringHelper.toMoney(order?.businessTax, 2),
  },
  {
    name: 'Delivery fee',
    value: StringHelper.toMoney(order?.deliveryFee, 2),
  },
  {
    name: 'Сomment',
    value: order?.comment || 'no comment',
  },
  // {
  //   name: 'Discount',
  //   value: StringHelper.toMoney(order?.discount, 2),
  // },
  // {
  //   name: 'Final price',
  //   value: StringHelper.toMoney(order?.finalPrice, 2),
  // },
  // {
  //   name: 'Approve',
  //   value: UITag({
  //     value: order?.approve,
  //     values: processAppreove,
  //   }),
  // },
];

export const columns = () => [
  {
    title: 'Id',
    dataIndex: 'product',
    key: 'product',
    render: (value: Pick<FullProduct, 'id'>) =>
      UICellTooltip({ text: value?.id.toString() }),
    width: 70,
  },
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
    width: COLUMN_WIDTH_NAME,
    render: (value: Pick<FullProduct, 'id' | 'image' | 'name' | 'price'>) =>
      UICellFullName({
        firstName: value?.name,
        src: value?.image?.path,
        link: `/${LINK_PRODUCT}/${value.id}`,
      }),
  },
  {
    title: 'Unit Price',
    dataIndex: 'unitPrice',
    key: 'unitPrice',
    width: COLUMN_WIDTH_NUMBER,
    render: (value: number) => StringHelper.toMoney(value, 2),
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    render: (value: number) => UICellTooltip({ text: value?.toString() }),
    width: 70,
  },
  {
    title: 'Total Price',
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    width: COLUMN_WIDTH_NUMBER,
    render: (value: number) => StringHelper.toMoney(value, 2),
  },
];
