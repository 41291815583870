/* eslint-disable indent */
import { useEffect, useState } from 'react';

import { Col, Form, Input, InputNumber, Radio, Row, Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useSelector } from 'react-redux';

import { en } from '@i18n';
import { LocalStorage } from '@services/storage';
import { RootState, useAppDispatch } from '@store/index';
import { clearOrderBasket, orderBasketRequest } from '@store/Order';
import {
  clearListProduct,
  getListProductRequest,
  getUserProductsRequest,
} from '@store/Product';
import {
  clearUserListStore,
  clearUserLocation,
  getListUserRequest,
  getOneUserRequest,
  getUserLocationRequest,
} from '@store/User';
import { UIButton, UISelectFormItem } from '@ui';
import {
  Button,
  META_DEFAULT,
  OptionSelect,
  ProcessStatus,
  Role,
  ShippingMethod,
  StringHelper,
  TransactionStatus,
} from '@utils';

import { INITIAL_VALUES } from './constant';
import {
  FormValues,
  FormValuesChange,
  ProductsOptions,
  Props,
} from './index.type';

const WidgetModalOrderForm = ({
  onFormSubmit,
  order,
  form,
  isCreate,
  products,
  basket,
}: Props) => {
  const dispatch = useAppDispatch();
  const [address, setAddress] = useState<OptionSelect | null>(null);

  const {
    productList,
    driversList,
    loading: loadingProduct,
    meta: metaProduct,
  } = useSelector((state: RootState) => state.product);

  const {
    userList,
    meta,
    loading: loadingUser,
    userLocation,
  } = useSelector((state: RootState) => state.user);

  const tips = Form.useWatch('tips', form);
  const userId = Form.useWatch('userId', form);
  const driverId = Form.useWatch('driverId', form);
  const shippingMethod = Form.useWatch('shippingMethod', form);
  const statusWatch = Form.useWatch('status', form);
  const arrAddresses = userList?.find((i) => i.id === userId)?.addresses;
  const filterOptions: ProductsOptions[] | null =
    LocalStorage.get('productOrder');

  const [productLocal, setProducts] = useState<
    ProductsOptions[] | null | undefined
  >(filterOptions);

  const setValueAll = (val: ProductsOptions[]) => {
    LocalStorage.set('productOrder', val);
    setProducts(val);
  };

  useEffect(() => {
    if (userList) {
      const defAddress = userList
        ?.find((i) => i.id === userId)
        ?.addresses?.find((i) => i.isDefault);

      if (defAddress) {
        setAddress({
          label: defAddress.formattedAddress || 'no address',
          value: defAddress.id,
        });
        // if (driverId && !order) {
        //   form.setFieldValue('shippingMethod', ShippingMethod.DELIVERY);
        // }
      } else {
        setAddress(null);
        // if (!driverId && !order) {
        //   form.setFieldValue('shippingMethod', ShippingMethod.PICKUP);
        // }
      }
    }
  }, [driverId, form, order, userList, userId]);

  useEffect(() => {
    return () => {
      LocalStorage.clear('productOrder');
    };
  }, [dispatch]);

  useEffect(() => {
    if (order) {
      form.setFieldValue('status', order?.status);
    }
    if (shippingMethod === ShippingMethod.PICKUP && !order) {
      form.setFieldValue('driverId', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, shippingMethod, order]);

  useEffect(() => {
    if (order) {
      if (statusWatch && statusWatch !== ProcessStatus.AWAITING_PICKUP) {
        setValueAll(
          (products || [])?.map((item) => ({
            quantity: item.quantity,
            id: item.product?.id,
            name: item.product?.name,
          })) as ProductsOptions[] | [],
        );
      }
      if (statusWatch && statusWatch !== ProcessStatus.PENDING_DRIVER) {
        form.setFieldsValue({
          tips: order?.tips,
        });
        if (order?.address) {
          setAddress({
            label: order?.address?.formattedAddress || 'no address',
            value: order?.address?.id,
          });
        }
        setValueAll(
          (products || [])?.map((item) => ({
            quantity: item.quantity,
            id: item.product?.id,
            name: item.product?.name,
          })) as ProductsOptions[] | [],
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, statusWatch, order]);

  useEffect(() => {
    if (order) {
      form.setFieldsValue({
        userId: order?.user?.id,
        driverId: order?.driver?.id,
        tips: order?.tips,
        // paymentMethod: order?.paymentMethod,
        status: order?.status,
        shippingMethod: order?.shippingMethod,
      } as Omit<FormValuesChange, 'id'>);
      if (order?.address) {
        setAddress({
          label: order?.address?.formattedAddress || 'no address',
          value: order?.address?.id,
        });
      } else {
        setAddress(null);
      }
      setValueAll(
        (products || []).map((item) => ({
          quantity: item?.quantity,
          id: item?.product?.id,
          name: item?.product?.name,
        })),
      );
    } else {
      form.setFieldsValue(INITIAL_VALUES);
    }
  }, [form, order, products]);

  const handlerFinish = (values: FormValues | FormValuesChange) => {
    const changeValue = values as FormValuesChange;

    if (order && statusWatch) {
      const data = {
        status: changeValue.status || order.status,
        id: order.id,
        ...(statusWatch === ProcessStatus.PENDING_DRIVER && {
          ...(address?.value && {
            addressId: +address.value,
          }),
          ...(changeValue?.driverId && {
            driverId: changeValue.driverId,
          }),
        }),
        ...(productLocal?.length &&
          (statusWatch === ProcessStatus.PENDING_DRIVER ||
            statusWatch === ProcessStatus.AWAITING_PICKUP) && {
            products: productLocal.map((item) => {
              const { name, ...val } = item;

              return val;
            }),
            // taxFee: 7.46,
            // ...(order.shippingMethod === ShippingMethod.DELIVERY && {
            //   deliveryFee: 8.5,
            // }),
          }),
      } as FormValuesChange;

      if (basket || order) {
        onFormSubmit(data);
      } else {
        const { tips: hidTips, userId: hidUserId, ...val } = data;

        dispatch(orderBasketRequest(val));
      }
    } else if (productLocal?.length) {
      const createValue = values as FormValues;
      const data = {
        userId: createValue.userId,
        tips: createValue.tips,
        shippingMethod: createValue.shippingMethod,
        products: productLocal.map((item) => {
          const { name, ...val } = item;

          return val;
        }),
        // taxFee: 7.46,
        ...(address?.value &&
          createValue.shippingMethod !== ShippingMethod.PICKUP && {
            addressId: +address.value,
          }),
        // ...(createValue.shippingMethod === ShippingMethod.DELIVERY && {
        //   deliveryFee: 8.5,
        // }),
      } as FormValues;

      if (basket || order) {
        onFormSubmit(data);
      } else {
        const { tips: hidTips, userId: hidUserId, ...val } = data;

        dispatch(orderBasketRequest(val));
      }
    }
  };

  const customItem = {
    labelCol: { span: 20 },
    wrapperCol: { span: 4 },
  };

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      requiredMark={false}
      size="large"
      initialValues={INITIAL_VALUES}
      onFinish={handlerFinish}
      autoComplete="off"
      onChange={() => dispatch(clearOrderBasket())}
      validateMessages={en.validation}
    >
      <UISelectFormItem
        label="User"
        name="userId"
        disabled={!!order}
        options={
          (userList || [])
            .map((item) => {
              if (item.profile?.fullName) {
                return {
                  id: item.id,
                  name: item.profile?.fullName,
                };
              }

              return null;
            })
            .filter((i) => i) as {
            id: number | string;
            name: string;
          }[]
        }
        onChange={() => dispatch(clearOrderBasket())}
        getOptions={(query) => dispatch(getListUserRequest(query, false))}
        getOption={(id) =>
          id && dispatch(getOneUserRequest({ id: +id, role: Role.USER }))
        }
        valueId={userId}
        clearOptions={() => dispatch(clearUserListStore())}
        queryString={`filter[role]=${Role.USER}&filter[isConfirmed21Age]=true`}
        meta={meta || META_DEFAULT}
        loading={loadingUser}
      />
      <UISelectFormItem
        label="Driver online"
        resending
        name="driverId"
        options={(userLocation.list || []).map((item) => ({
          id: item.id,
          name: item.profile?.fullName,
        }))}
        onChange={() => dispatch(clearOrderBasket())}
        disabled={
          !!order &&
          (order?.shippingMethod !== ShippingMethod.DELIVERY ||
            statusWatch !== ProcessStatus.PENDING_DRIVER)
        }
        getOptions={() => dispatch(getUserLocationRequest())}
        clearOptions={() => dispatch(clearUserLocation())}
        getOption={(id) =>
          id && dispatch(getOneUserRequest({ id: +id, location: true }))
        }
        valueId={driverId}
        queryString={`filter[role]=${Role.DRIVERS}`}
        meta={userLocation.meta || META_DEFAULT}
        loading={loadingUser}
      />
      {arrAddresses && address && (
        <UISelectFormItem
          label="Address delivery"
          disabled={
            !(
              (order &&
                order.status === ProcessStatus.VERIFICATION_REQUIRED &&
                statusWatch === ProcessStatus.PENDING_DRIVER) ||
              !order
            )
          }
          value={address}
          options={arrAddresses?.map((item) => ({
            id: item.id,
            name: item.formattedAddress || 'no address',
          }))}
          onChange={(i, val) => {
            setAddress(val as OptionSelect);
            dispatch(clearOrderBasket());
          }}
        />
      )}
      {/* <Form.Item label="Payment method" name="paymentMethod">
        <Radio.Group disabled={!!order} size="large">
          <Radio.Button value={PaymentMethod.CASH}>cash</Radio.Button>
          <Radio.Button value={PaymentMethod.CARD}>card</Radio.Button>
        </Radio.Group>
      </Form.Item> */}
      {order ? (
        <Form.Item label="Status" name="status">
          <Radio.Group size="large">
            <Radio.Button value={ProcessStatus.CANCELED}>Canceled</Radio.Button>
            <Radio.Button value={ProcessStatus.COMPLETED}>
              Completed
            </Radio.Button>
            {order.status === ProcessStatus.VERIFICATION_REQUIRED ? (
              <>
                {order.shippingMethod === ShippingMethod.PICKUP && (
                  <Radio.Button value={ProcessStatus.AWAITING_PICKUP}>
                    {TransactionStatus.AWAITING_PICKUP}
                  </Radio.Button>
                )}
                {order.shippingMethod === ShippingMethod.DELIVERY && (
                  <Radio.Button value={ProcessStatus.PENDING_DRIVER}>
                    {TransactionStatus.PENDING_DRIVER}
                  </Radio.Button>
                )}
              </>
            ) : null}
          </Radio.Group>
        </Form.Item>
      ) : null}
      <Form.Item hidden label="Shipping method" name="shippingMethod">
        <Radio.Group size="large">
          <Radio.Button
            disabled={
              !!order && order.shippingMethod !== ShippingMethod.DELIVERY
            }
            value={ShippingMethod.DELIVERY}
          >
            delivery
          </Radio.Button>
          <Radio.Button
            disabled={!!order && order.shippingMethod !== ShippingMethod.PICKUP}
            value={ShippingMethod.PICKUP}
          >
            pickup
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Tips" name="tips">
        <Input
          disabled={
            !(
              (order &&
                statusWatch === ProcessStatus.PENDING_DRIVER &&
                order.status === ProcessStatus.VERIFICATION_REQUIRED) ||
              !order
            )
          }
          min={0}
          type="number"
          {...(+tips < 0 && { status: 'error' })}
          placeholder="Tips"
        />
      </Form.Item>
      <UISelectFormItem
        label="Products"
        disabled={
          !(
            driverId &&
            ((order &&
              ((statusWatch === ProcessStatus.AWAITING_PICKUP &&
                order.status === ProcessStatus.VERIFICATION_REQUIRED) ||
                (statusWatch === ProcessStatus.PENDING_DRIVER &&
                  order.status === ProcessStatus.VERIFICATION_REQUIRED))) ||
              !order)
          )
        }
        value={productLocal?.map((i) => ({
          label: i.name,
          value: i.id,
        }))}
        mode="multiple"
        onChange={(ids, values) => {
          const newValue = (ids as number[])?.map((id) =>
            [
              ...(values as OptionSelect[]),
              ...((productLocal || []).map((i) => ({
                label: i.name,
                value: i.id,
              })) || []),
            ].find((e) => +e.value === +id),
          );

          dispatch(clearOrderBasket());
          setValueAll(
            (newValue as OptionSelect[]).map((e) => ({
              id: +e.value,
              quantity:
                productLocal?.find((item) => +item.id === +e.value)?.quantity ||
                1,
              name: e.label,
            })),
          );
        }}
        options={
          (driverId
            ? driversList?.map((item) => ({
                id: item.productId,
                name: item?.product?.name || `product id - ${item.productId}`,
              }))
            : productList?.map((item) => ({
                id: item.id,
                name: item.name,
              }))) || []
        }
        getOptions={(query) => {
          if (driverId) {
            dispatch(
              getUserProductsRequest({
                id: driverId,
                query: `${query}&filter[isActive]=true`,
              }),
            );
          } else {
            dispatch(getListProductRequest(`${query}&filter[isActive]=true`));
          }
        }}
        onResending={!!driverId}
        clearOptions={() => dispatch(clearListProduct())}
        meta={metaProduct}
        loading={loadingProduct}
      />
      {productLocal?.map((item, index) => (
        <Form.Item
          key={index}
          {...customItem}
          label={`${item?.name} - quantity`}
        >
          <InputNumber
            disabled={
              !(
                driverId &&
                ((order &&
                  ((statusWatch === ProcessStatus.AWAITING_PICKUP &&
                    order.status === ProcessStatus.VERIFICATION_REQUIRED) ||
                    (statusWatch === ProcessStatus.PENDING_DRIVER &&
                      order.status === ProcessStatus.VERIFICATION_REQUIRED))) ||
                  !order)
              )
            }
            value={+item.quantity}
            {...(+item.quantity < 1 && { status: 'error' })}
            min={1}
            type="number"
            placeholder="Quantity"
            max={
              driversList?.find((item2) => +item2.productId === +item.id)
                ?.quantity
            }
            onChange={(e) => {
              setValueAll([
                ...productLocal.map((item2) => {
                  if (item.id === item2.id) {
                    return {
                      ...item2,
                      quantity: +e,
                    };
                  }

                  return item2;
                }),
              ]);
            }}
          />
        </Form.Item>
      ))}
      <Space direction="vertical" style={{ display: 'flex' }}>
        {!order ? (
          <UIButton
            key="Basket calculation"
            disabled={
              !(
                productLocal?.length &&
                userId &&
                ((shippingMethod === ShippingMethod.PICKUP && !driverId) ||
                  (shippingMethod === ShippingMethod.DELIVERY && driverId) ||
                  statusWatch === ProcessStatus.CANCELED ||
                  statusWatch === ProcessStatus.COMPLETED)
              )
            }
            typeAntBtn="primary"
            handler={() => form.submit()}
            type={Button.ANT_BUTTON}
            size="large"
            className="edit-form__save-button"
          >
            Basket calculation
          </UIButton>
        ) : null}
        {basket ? (
          <Row>
            <Col span={12}>
              <div>
                <Text strong>Subtotal: </Text>
                {basket.subTotalPrice
                  ? StringHelper.toMoney(basket.subTotalPrice, 2)
                  : 'No data'}
              </div>
              <div>
                <Text strong>Delivery fee: </Text>
                {StringHelper.toMoney(basket.deliveryFee || 0, 2)}
              </div>
              <div>
                <Text strong>Discount: </Text>
                {StringHelper.toMoney(basket.discountPrice || 0, 2)}
              </div>
              <div>
                <Text strong>Taxes: </Text>
                {StringHelper.toMoney(
                  (basket.exciseTax || 0) +
                    (basket.salesTax || 0) +
                    (basket.businessTax || 0),
                  2,
                )}
              </div>
              <div>
                <Text strong>Excise Tax: </Text>
                {StringHelper.toMoney(basket.exciseTax || 0, 2)}
              </div>
              <div>
                <Text strong>Sales Tax: </Text>
                {StringHelper.toMoney(basket.salesTax || 0, 2)}
              </div>
              <div>
                <Text strong>Business Tax: </Text>
                {StringHelper.toMoney(basket.businessTax || 0, 2)}
              </div>
            </Col>
            <Col span={12}>
              <div>
                <Text strong>Count of pack: </Text>
                {basket?.countOfPack}
              </div>
              <div>
                <Text strong>Count of product: </Text>
                {basket?.countOfProduct}
              </div>
              <div>
                <Text strong>Reward amount: </Text>
                {basket?.rewardAmount}
              </div>
              <div>
                <Text strong>Total: </Text>
                {basket.totalPrice
                  ? StringHelper.toMoney(basket.totalPrice, 2)
                  : 'No data'}
              </div>
            </Col>
          </Row>
        ) : null}
        <UIButton
          key="Save changes"
          disabled={
            !(
              productLocal?.length &&
              (basket || order) &&
              userId &&
              ((shippingMethod === ShippingMethod.PICKUP && !driverId) ||
                (shippingMethod === ShippingMethod.DELIVERY && driverId) ||
                statusWatch === ProcessStatus.CANCELED ||
                statusWatch === ProcessStatus.COMPLETED)
            )
          }
          typeAntBtn="primary"
          handler={() => form.submit()}
          type={Button.ANT_BUTTON}
          size="large"
          className="edit-form__save-button"
        >
          {isCreate ? 'Add order' : 'Save'}
        </UIButton>
      </Space>
    </Form>
  );
};

export default WidgetModalOrderForm;
