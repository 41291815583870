import { useSelector } from 'react-redux';

import { RootState } from '@store/index';
import { ModalType } from '@store/Modal';

import WidgetModalBrand from './Brand';
import WidgetModalCategory from './Category';
import WidgetModalConfirm from './Confirm';
import WidgetEditQuantities from './EditQuantities';
import WidgetEditQuantity from './EditQuantity';
import WidgetModalEffect from './Effect';
import UIModalFilter from './Filter';
import WidgetModalOrder from './Order';
import WidgetModalProduct from './Product';
import WidgetModalProfile from './Profile';
import WidgetModalPromoCode from './PromoCode';
import { WidgetModalReviewBrandEdit } from './ReviewBrand';
import { WidgetModalReviewUserEdit } from './ReviewUser';
import WidgetModalReward from './Reward';
import WidgetModalShop from './Shop';
import WidgetModalTaxes from './Taxes';
import WidgetModalUser from './User';

const WidgetModal = () => {
  const { modalType, modalProps } = useSelector(
    (state: RootState) => state.modal,
  );

  switch (modalType) {
    case ModalType.FILTER:
      return <UIModalFilter {...modalProps} />;
    case ModalType.PROFILE:
      return <WidgetModalProfile {...modalProps} />;
    case ModalType.USER:
      return <WidgetModalUser {...modalProps} />;
    case ModalType.REWARD:
      return <WidgetModalReward {...modalProps} />;
    case ModalType.TAXES:
      return <WidgetModalTaxes {...modalProps} />;
    case ModalType.CONFIRM:
      return <WidgetModalConfirm {...modalProps} />;
    case ModalType.CREATE_ORDER:
      return <WidgetModalOrder {...modalProps} />;
    case ModalType.CREATE_PRODUCTS:
      return <WidgetModalProduct {...modalProps} />;
    case ModalType.CREATE_BRAND:
      return <WidgetModalBrand {...modalProps} />;
    case ModalType.CREATE_PROMO_CODE:
      return <WidgetModalPromoCode {...modalProps} />;
    case ModalType.CREATE_SHOP:
      return <WidgetModalShop {...modalProps} />;
    case ModalType.CREATE_CATEGORY:
      return <WidgetModalCategory {...modalProps} />;
    case ModalType.CREATE_EFFECT:
      return <WidgetModalEffect {...modalProps} />;
    case ModalType.EDIT_REVIEW_BRAND:
      return <WidgetModalReviewBrandEdit {...modalProps} />;
    case ModalType.EDIT_REVIEW_USER:
      return <WidgetModalReviewUserEdit {...modalProps} />;
    case ModalType.EDIT_QUANTITY:
      return <WidgetEditQuantity {...modalProps} />;
    case ModalType.EDIT_QUANTITIES:
      return <WidgetEditQuantities {...modalProps} />;
    default:
      return null;
  }
};

export default WidgetModal;
