/* eslint-disable indent */
import {
  CalendarOutlined,
  ClusterOutlined,
  DollarOutlined,
  FireOutlined,
  PieChartOutlined,
  SettingOutlined,
  SnippetsOutlined,
  StarOutlined,
  TagOutlined,
  UserOutlined,
  WindowsOutlined,
} from '@ant-design/icons';

import { ReactComponent as ChatIcon } from '@assets/svg/chat_icon.svg';
import {
  LINK_BRAND,
  LINK_CATEGORY,
  LINK_CHAT,
  LINK_CSV,
  LINK_EFFECT,
  LINK_ORDER,
  LINK_PRODUCT,
  LINK_PROMO_CODE,
  LINK_REVIEW,
  LINK_SETTING,
  LINK_SUPPORT,
  LINK_USERS,
  Role,
} from '@utils';

export const mainMenu = (
  notification: { [key in string]: number },
  role?: string,
) => {
  // const role = CookieStorage.parseToken()?.role;

  return [
    [
      {
        title: 'Dashboard',
        link: '/',
        icon: <PieChartOutlined />,
      },
      {
        title: 'Product',
        link: `/${LINK_PRODUCT}`,
        icon: <DollarOutlined />,
      },
      {
        title: 'User',
        link: `/${LINK_USERS}`,
        icon: <UserOutlined />,
      },
      {
        title: 'Order',
        link: `/${LINK_ORDER}`,
        icon: <CalendarOutlined />,
        notification: notification[LINK_ORDER],
      },
      ...(role === Role.ADMIN
        ? [
            {
              title: 'Promo code',
              link: `/${LINK_PROMO_CODE}`,
              icon: <TagOutlined />,
            },
            {
              title: 'Brand',
              link: `/${LINK_BRAND}`,
              icon: <WindowsOutlined />,
            },
            {
              title: 'Effect',
              link: `/${LINK_EFFECT}`,
              icon: <FireOutlined />,
            },
            {
              title: 'Category',
              link: `/${LINK_CATEGORY}`,
              icon: <ClusterOutlined />,
            },
            // {
            //   title: 'Store',
            //   link: `/${LINK_SHOP}`,
            //   icon: <AccountBookOutlined />,
            // },
            {
              title: 'Review',
              link: `/${LINK_REVIEW}`,
              icon: <StarOutlined />,
            },
            {
              title: 'Csv/xlsx',
              link: `/${LINK_CSV}`,
              icon: <SnippetsOutlined />,
            },
          ]
        : []),
      {
        title: 'Support',
        link: `/${LINK_SUPPORT}`,
        icon: <ChatIcon />,
        notification: notification[LINK_SUPPORT],
      },
      {
        title: 'Chats',
        link: `/${LINK_CHAT}`,
        icon: <ChatIcon />,
      },
      {
        title: 'Settings',
        link: `/${LINK_SETTING}`,
        icon: <SettingOutlined />,
      },
      // {
      //   title: 'Transactions',
      //   link: `/${LINK_TRANSACTION}`,
      //   icon: <RetweetOutlined />,
      // },
      // {
      //   title: 'Payouts',
      //   link: `/${LINK_PAYOUT}`,
      //   icon: <CreditCardOutlined />,
      // },
    ],
    [],
  ];
};
