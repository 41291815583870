import {
  FormFullProduct,
  FullProduct,
  FullStoresList,
} from '@entities/Product';
import { FullDriversList, FullUser } from '@entities/User';

import {
  CHANGE_PRODUCT_DRIVERS_FAILURE,
  CHANGE_PRODUCT_DRIVERS_REQUEST,
  CHANGE_PRODUCT_DRIVERS_SUCCESS,
  CHANGE_PRODUCT_STORES_FAILURE,
  CHANGE_PRODUCT_STORES_REQUEST,
  CHANGE_PRODUCT_STORES_SUCCESS,
  CLEAR_IMAGE_TO_PRODUCTS,
  CLEAR_LIST_PRODUCTS,
  CLEAR_PRODUCTS,
  CLEAR_PRODUCTS_DRIVERS,
  CLEAR_PRODUCTS_STORES,
  CLEAR_PROMOTION_IMAGE,
  CREATE_PRODUCTS_FAILURE,
  CREATE_PRODUCTS_REQUEST,
  CREATE_PRODUCTS_SUCCESS,
  DELETE_PRODUCTS_DRIVERS_FAILURE,
  DELETE_PRODUCTS_DRIVERS_REQUEST,
  DELETE_PRODUCTS_DRIVERS_SUCCESS,
  DELETE_PRODUCTS_FAILURE,
  DELETE_PRODUCTS_REQUEST,
  DELETE_PRODUCTS_SUCCESS,
  DELETE_PRODUCT_STORE_FAILURE,
  DELETE_PRODUCT_STORE_REQUEST,
  DELETE_PRODUCT_STORE_SUCCESS,
  GET_DRIVERS_PRODUCTS_FAILURE,
  GET_DRIVERS_PRODUCTS_REQUEST,
  GET_DRIVERS_PRODUCTS_SUCCESS,
  GET_LIST_PRODUCTS_FAILURE,
  GET_LIST_PRODUCTS_REQUEST,
  GET_LIST_PRODUCTS_SUCCESS,
  GET_ONE_PRODUCTS_FAILURE,
  GET_ONE_PRODUCTS_REQUEST,
  GET_ONE_PRODUCTS_SUCCESS,
  GET_SHOP_PRODUCTS_FAILURE,
  GET_SHOP_PRODUCTS_REQUEST,
  GET_SHOP_PRODUCTS_SUCCESS,
  GET_STORES_PRODUCTS_FAILURE,
  GET_STORES_PRODUCTS_REQUEST,
  GET_STORES_PRODUCTS_SUCCESS,
  GET_USER_PRODUCTS_FAILURE,
  GET_USER_PRODUCTS_REQUEST,
  GET_USER_PRODUCTS_SUCCESS,
  UPDATE_PRODUCTS_FAILURE,
  UPDATE_PRODUCTS_REQUEST,
  UPDATE_PRODUCTS_SUCCESS,
} from './Product.constant';
import { ProductActionsTypes } from './Product.type';

export function getUserProductsRequest(payload: {
  id: number;
  isCancel?: boolean | undefined;
  query: string;
}): ProductActionsTypes {
  return {
    type: GET_USER_PRODUCTS_REQUEST,
    payload,
  };
}
export function getUserProductsSuccess(
  payload: DataResponse<{
    products: FullDriversList[];
    user: FullUser;
  }>,
): ProductActionsTypes {
  return {
    type: GET_USER_PRODUCTS_SUCCESS,
    payload,
  };
}
export function getUserProductsFailure(): ProductActionsTypes {
  return {
    type: GET_USER_PRODUCTS_FAILURE,
  };
}

export function getShopProductsRequest(payload: {
  id: number;
  isCancel?: boolean | undefined;
  query: string;
}): ProductActionsTypes {
  return {
    type: GET_SHOP_PRODUCTS_REQUEST,
    payload,
  };
}
export function getShopProductsSuccess(
  payload: DataResponse<FullStoresList[]>,
): ProductActionsTypes {
  return {
    type: GET_SHOP_PRODUCTS_SUCCESS,
    payload,
  };
}
export function getShopProductsFailure(): ProductActionsTypes {
  return {
    type: GET_SHOP_PRODUCTS_FAILURE,
  };
}

export function changeProductDriversRequest(payload: {
  body: {
    driverId: number;
    quantity: number;
  }[];
  id: number;
}): ProductActionsTypes {
  return {
    type: CHANGE_PRODUCT_DRIVERS_REQUEST,
    payload,
  };
}
export function changeProductDriversSuccess(): ProductActionsTypes {
  return {
    type: CHANGE_PRODUCT_DRIVERS_SUCCESS,
  };
}
export function changeProductDriversFailure(): ProductActionsTypes {
  return {
    type: CHANGE_PRODUCT_DRIVERS_FAILURE,
  };
}

export function changeProductStoresRequest(payload: {
  body: {
    quantity: number;
    storeId: number;
  }[];
  id: number;
}): ProductActionsTypes {
  return {
    type: CHANGE_PRODUCT_STORES_REQUEST,
    payload,
  };
}
export function changeProductStoresSuccess(): ProductActionsTypes {
  return {
    type: CHANGE_PRODUCT_STORES_SUCCESS,
  };
}
export function changeProductStoresFailure(): ProductActionsTypes {
  return {
    type: CHANGE_PRODUCT_STORES_FAILURE,
  };
}

export function deleteProductStoresRequest(payload: {
  id: number;
  storeId: number;
}): ProductActionsTypes {
  return {
    type: DELETE_PRODUCT_STORE_REQUEST,
    payload,
  };
}
export function deleteProductStoresSuccess(): ProductActionsTypes {
  return {
    type: DELETE_PRODUCT_STORE_SUCCESS,
  };
}
export function deleteProductStoresFailure(): ProductActionsTypes {
  return {
    type: DELETE_PRODUCT_STORE_FAILURE,
  };
}

export function deleteProductDriversRequest(payload: {
  driverId: number;
  id: number;
}): ProductActionsTypes {
  return {
    type: DELETE_PRODUCTS_DRIVERS_REQUEST,
    payload,
  };
}
export function deleteProductDriversSuccess(): ProductActionsTypes {
  return {
    type: DELETE_PRODUCTS_DRIVERS_SUCCESS,
  };
}
export function deleteProductDriversFailure(): ProductActionsTypes {
  return {
    type: DELETE_PRODUCTS_DRIVERS_FAILURE,
  };
}

export function getListProductRequest(
  query?: Query['query'],
): ProductActionsTypes {
  return {
    type: GET_LIST_PRODUCTS_REQUEST,
    payload: {
      query,
    },
  };
}
export function getListProductSuccess(
  payload: DataResponse<FullProduct[]>,
): ProductActionsTypes {
  return {
    type: GET_LIST_PRODUCTS_SUCCESS,
    payload,
  };
}
export function getListProductFailure(): ProductActionsTypes {
  return {
    type: GET_LIST_PRODUCTS_FAILURE,
  };
}

export function createProductRequest(
  payload: Omit<FullProduct, FormFullProduct> &
    Partial<Pick<FullProduct, FormFullProduct>>,
): ProductActionsTypes {
  return {
    type: CREATE_PRODUCTS_REQUEST,
    payload,
  };
}
export function createProductSuccess(
  payload: FullProduct,
): ProductActionsTypes {
  return {
    type: CREATE_PRODUCTS_SUCCESS,
    payload,
  };
}
export function createProductFailure(): ProductActionsTypes {
  return {
    type: CREATE_PRODUCTS_FAILURE,
  };
}

export function deleteProductRequest(
  id: FullProduct['id'],
): ProductActionsTypes {
  return {
    type: DELETE_PRODUCTS_REQUEST,
    payload: {
      id,
    },
  };
}
export function deleteProductSuccess(
  payload: FullProduct['id'],
): ProductActionsTypes {
  return {
    type: DELETE_PRODUCTS_SUCCESS,
    payload,
  };
}
export function deleteProductFailure(): ProductActionsTypes {
  return {
    type: DELETE_PRODUCTS_FAILURE,
  };
}

export function getStoresProductRequest(
  id: FullProduct['id'],
  query?: Query['query'],
  isCancel?: boolean,
): ProductActionsTypes {
  return {
    type: GET_STORES_PRODUCTS_REQUEST,
    payload: {
      id,
      query,
      isCancel,
    },
  };
}
export function getStoresProductSuccess(
  payload: DataResponse<FullStoresList[]>,
): ProductActionsTypes {
  return {
    type: GET_STORES_PRODUCTS_SUCCESS,
    payload,
  };
}
export function getStoresProductFailure(): ProductActionsTypes {
  return {
    type: GET_STORES_PRODUCTS_FAILURE,
  };
}

export function getDriversProductRequest(
  id: FullProduct['id'],
  query?: Query['query'],
  isCancel?: boolean,
): ProductActionsTypes {
  return {
    type: GET_DRIVERS_PRODUCTS_REQUEST,
    payload: {
      id,
      query,
      isCancel,
    },
  };
}
export function getDriversProductSuccess(
  payload: DataResponse<FullDriversList[]>,
): ProductActionsTypes {
  return {
    type: GET_DRIVERS_PRODUCTS_SUCCESS,
    payload,
  };
}
export function getDriversProductFailure(): ProductActionsTypes {
  return {
    type: GET_DRIVERS_PRODUCTS_FAILURE,
  };
}

export function getOneProductRequest(
  id: FullProduct['id'],
): ProductActionsTypes {
  return {
    type: GET_ONE_PRODUCTS_REQUEST,
    payload: {
      id,
    },
  };
}
export function getOneProductSuccess(
  payload: FullProduct,
): ProductActionsTypes {
  return {
    type: GET_ONE_PRODUCTS_SUCCESS,
    payload,
  };
}
export function getOneProductFailure(): ProductActionsTypes {
  return {
    type: GET_ONE_PRODUCTS_FAILURE,
  };
}

export function updateProductRequest(
  payload: Pick<FullProduct, 'id'> & Partial<FullProduct>,
): ProductActionsTypes {
  return {
    type: UPDATE_PRODUCTS_REQUEST,
    payload,
  };
}
export function updateProductSuccess(
  payload: FullProduct,
): ProductActionsTypes {
  return {
    type: UPDATE_PRODUCTS_SUCCESS,
    payload,
  };
}
export function updateProductFailure(): ProductActionsTypes {
  return {
    type: UPDATE_PRODUCTS_FAILURE,
  };
}

export function clearProduct(): ProductActionsTypes {
  return {
    type: CLEAR_PRODUCTS,
  };
}

export function clearProductDrivers(): ProductActionsTypes {
  return {
    type: CLEAR_PRODUCTS_DRIVERS,
  };
}

export function clearProductStores(): ProductActionsTypes {
  return {
    type: CLEAR_PRODUCTS_STORES,
  };
}

export function clearListProduct(): ProductActionsTypes {
  return {
    type: CLEAR_LIST_PRODUCTS,
  };
}

export function clearImageToProduct(): ProductActionsTypes {
  return {
    type: CLEAR_IMAGE_TO_PRODUCTS,
  };
}

export function clearPromotionImage(): ProductActionsTypes {
  return {
    type: CLEAR_PROMOTION_IMAGE,
  };
}
