import { ActionStatus, META_DEFAULT } from '@utils';

import {
  CHANGE_PRODUCT_DRIVERS_FAILURE,
  CHANGE_PRODUCT_DRIVERS_REQUEST,
  CHANGE_PRODUCT_DRIVERS_SUCCESS,
  CHANGE_PRODUCT_STORES_FAILURE,
  CHANGE_PRODUCT_STORES_REQUEST,
  CHANGE_PRODUCT_STORES_SUCCESS,
  CLEAR_IMAGE_TO_PRODUCTS,
  CLEAR_LIST_PRODUCTS,
  CLEAR_PRODUCTS,
  CLEAR_PRODUCTS_DRIVERS,
  CLEAR_PRODUCTS_STORES,
  CLEAR_PROMOTION_IMAGE,
  CREATE_PRODUCTS_FAILURE,
  CREATE_PRODUCTS_REQUEST,
  CREATE_PRODUCTS_SUCCESS,
  DELETE_PRODUCTS_DRIVERS_FAILURE,
  DELETE_PRODUCTS_DRIVERS_REQUEST,
  DELETE_PRODUCTS_DRIVERS_SUCCESS,
  DELETE_PRODUCTS_SUCCESS,
  DELETE_PRODUCT_STORE_FAILURE,
  DELETE_PRODUCT_STORE_REQUEST,
  DELETE_PRODUCT_STORE_SUCCESS,
  GET_DRIVERS_PRODUCTS_FAILURE,
  GET_DRIVERS_PRODUCTS_REQUEST,
  GET_DRIVERS_PRODUCTS_SUCCESS,
  GET_LIST_PRODUCTS_FAILURE,
  GET_LIST_PRODUCTS_REQUEST,
  GET_LIST_PRODUCTS_SUCCESS,
  GET_ONE_PRODUCTS_FAILURE,
  GET_ONE_PRODUCTS_REQUEST,
  GET_ONE_PRODUCTS_SUCCESS,
  GET_SHOP_PRODUCTS_FAILURE,
  GET_SHOP_PRODUCTS_REQUEST,
  GET_SHOP_PRODUCTS_SUCCESS,
  GET_STORES_PRODUCTS_FAILURE,
  GET_STORES_PRODUCTS_REQUEST,
  GET_STORES_PRODUCTS_SUCCESS,
  GET_USER_PRODUCTS_FAILURE,
  GET_USER_PRODUCTS_REQUEST,
  GET_USER_PRODUCTS_SUCCESS,
  UPDATE_PRODUCTS_FAILURE,
  UPDATE_PRODUCTS_REQUEST,
  UPDATE_PRODUCTS_SUCCESS,
} from './Product.constant';
import { IProductState, ProductActionsTypes } from './Product.type';

const initialState: IProductState = {
  status: ActionStatus.NONE,
  productList: null,
  meta: META_DEFAULT,
  product: null,
  storesList: null,
  driversList: null,
  loading: false,
  isChangeDriversList: false,
  isChangeStoresList: false,
};

export default function ProductReducer(
  state = initialState,
  action: ProductActionsTypes,
): IProductState {
  switch (action.type) {
    case GET_LIST_PRODUCTS_REQUEST:
    case GET_USER_PRODUCTS_REQUEST:
    case GET_SHOP_PRODUCTS_REQUEST:
    case GET_ONE_PRODUCTS_REQUEST:
    case GET_STORES_PRODUCTS_REQUEST:
    case GET_DRIVERS_PRODUCTS_REQUEST:
      return {
        ...state,
        status: ActionStatus.NONE,
        loading: true,
      };
    case CHANGE_PRODUCT_DRIVERS_REQUEST:
    case CHANGE_PRODUCT_STORES_REQUEST:
    case DELETE_PRODUCTS_DRIVERS_REQUEST:
    case DELETE_PRODUCT_STORE_REQUEST:
      return {
        ...state,
        isChangeDriversList: false,
        isChangeStoresList: false,
        loading: true,
      };

    case GET_LIST_PRODUCTS_SUCCESS:
      return {
        ...state,
        productList: [...(state.productList || []), ...action.payload.data],
        meta: action.payload.meta,
        loading: false,
      };
    case GET_USER_PRODUCTS_SUCCESS: {
      return {
        ...state,
        driversList: [
          ...(state.driversList || []),
          ...action.payload.data.products,
        ],
        meta: action.payload.meta,
        loading: false,
      };
    }
    case GET_SHOP_PRODUCTS_SUCCESS: {
      return {
        ...state,
        storesList: [...(state.storesList || []), ...action.payload.data],
        meta: action.payload.meta,
        loading: false,
      };
    }
    case DELETE_PRODUCTS_DRIVERS_SUCCESS:
    case CHANGE_PRODUCT_DRIVERS_SUCCESS: {
      return {
        ...state,
        isChangeDriversList: true,
        loading: false,
      };
    }
    case DELETE_PRODUCT_STORE_SUCCESS:
    case CHANGE_PRODUCT_STORES_SUCCESS: {
      return {
        ...state,
        isChangeStoresList: true,
        loading: false,
      };
    }
    case GET_LIST_PRODUCTS_FAILURE:
    case GET_USER_PRODUCTS_FAILURE:
    case GET_SHOP_PRODUCTS_FAILURE:
    case DELETE_PRODUCTS_DRIVERS_FAILURE:
    case DELETE_PRODUCT_STORE_FAILURE:
    case CHANGE_PRODUCT_DRIVERS_FAILURE:
    case CHANGE_PRODUCT_STORES_FAILURE:
    case UPDATE_PRODUCTS_FAILURE:
    case CREATE_PRODUCTS_FAILURE:
    case GET_ONE_PRODUCTS_FAILURE:
    case GET_STORES_PRODUCTS_FAILURE:
    case GET_DRIVERS_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CREATE_PRODUCTS_REQUEST:
    case UPDATE_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PRODUCTS_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.CREATED,
        loading: false,
        productList: [action.payload, ...(state.productList || [])],
      };
    }
    case UPDATE_PRODUCTS_SUCCESS:
      return {
        ...state,
        status: ActionStatus.UPDATED,
        loading: false,
        product: action.payload,
        productList: (state.productList || []).map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }

          return item;
        }),
      };
    case DELETE_PRODUCTS_SUCCESS: {
      return {
        ...state,
        status: ActionStatus.DELETED,
        loading: false,
        productList: (state.productList || []).filter(
          (item) => item.id !== action.payload,
        ),
      };
    }
    case GET_ONE_PRODUCTS_SUCCESS: {
      return {
        ...state,
        product: action.payload,
        loading: false,
      };
    }
    case GET_STORES_PRODUCTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        storesList: action.payload.data,
        meta: action.payload.meta,
      };
    }
    case GET_DRIVERS_PRODUCTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        driversList: action.payload.data,
        meta: action.payload.meta,
      };
    }
    case CLEAR_PRODUCTS_DRIVERS: {
      return {
        ...state,
        status: ActionStatus.NONE,
        driversList: null,
      };
    }
    case CLEAR_PRODUCTS_STORES: {
      return {
        ...state,
        status: ActionStatus.NONE,
        storesList: null,
      };
    }
    case CLEAR_PRODUCTS: {
      return {
        ...state,
        status: ActionStatus.NONE,
        product: null,
      };
    }
    case CLEAR_LIST_PRODUCTS: {
      return {
        ...state,
        productList: null,
        meta: META_DEFAULT,
      };
    }
    case CLEAR_IMAGE_TO_PRODUCTS: {
      return {
        ...state,
        ...(state.product && {
          product: { ...state.product, image: null },
        }),
      };
    }
    case CLEAR_PROMOTION_IMAGE: {
      return {
        ...state,
        ...(state.product && {
          product: { ...state.product, promotionImage: null },
        }),
      };
    }
    default:
      return state;
  }
}
