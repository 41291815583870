import {
  ForkEffect,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import type { FullUser } from '@entities/index';
import { UserHttp } from '@services/http';
import { alertError, alertSuccess } from '@store/Alert';
import { hideModal } from '@store/Modal';
import { LINK_USERS, QueryHelper, Role } from '@utils';

import {
  createUserFailure,
  createUserSuccess,
  deleteUserFailure,
  deleteUserSuccess,
  getListUserFailure,
  getListUserSuccess,
  getOneUserFailure,
  getOneUserSuccess,
  getSearchListUserFailure,
  getSearchListUserSuccess,
  getUserLocationFailure,
  getUserLocationSuccess,
  getUsersSuccess,
  resetUserPasswordFailure,
  resetUserPasswordSuccess,
  updateUserFailure,
  updateUserSuccess,
} from './User.action';
import {
  CREATE_USER_REQUEST,
  DELETE_USER_REQUEST,
  GET_LIST_USER_REQUEST,
  GET_ONE_USER_REQUEST,
  GET_SEARCH_LIST_USER_REQUEST,
  GET_USER_LOCATION_REQUEST,
  RESET_USER_PASSWORD_REQUEST,
  UPDATE_USER_REQUEST,
} from './User.constant';
import {
  ICreateUserRequest,
  IDeleteUserRequest,
  IGetListUserRequest,
  IGetOneUserRequest,
  IGetSearchListUserRequest,
  IResetUserPasswordRequest,
  IUpdateUserRequest,
} from './User.type';

function* workerGetListUser(action: IGetListUserRequest) {
  try {
    const { query, isCancel } = action.payload;
    const objQuery = QueryHelper.parse(query);
    const data: DataResponse<FullUser[]> = yield call(
      UserHttp.getListUser,
      query,
      isCancel,
    );

    if (objQuery.filter?.ids) {
      yield put(getUsersSuccess({ data: data.data }));
    }
    yield put(
      getListUserSuccess({
        ...data,
        ...(objQuery.filter?.role && {
          role: objQuery.filter?.role as Role,
        }),
      }),
    );
  } catch (error) {
    yield put(getListUserFailure());
    if (typeof error !== 'string') {
      yield put(alertError(error));
    }
  }
}

function* workerGetUserLocation() {
  try {
    const data: DataResponse<FullUser[]> = yield call(UserHttp.getLocation);

    yield put(getUserLocationSuccess(data));
  } catch (error) {
    yield put(getUserLocationFailure());
    yield put(alertError(error));
  }
}

function* workerGetOneUser(action: IGetOneUserRequest) {
  try {
    const { id, role, location } = action.payload;
    const user: FullUser = yield call(UserHttp.getOneUser, id);

    yield put(
      getOneUserSuccess({
        user,
        ...(role && { role }),
        ...(location && { location }),
      }),
    );
  } catch (error) {
    yield put(getOneUserFailure());
    yield put(alertError(error));
  }
}

function* workerUpdateUser(action: IUpdateUserRequest) {
  try {
    const user: FullUser = yield call(UserHttp.updateUser, action.payload);

    yield put(updateUserSuccess(user));
    yield put(alertSuccess('User uploaded successfully!'));
    yield put(hideModal());
  } catch (error) {
    yield put(updateUserFailure());
    yield put(alertError(error));
  }
}

function* workerDeleteUser(action: IDeleteUserRequest) {
  try {
    const { id } = action.payload;

    yield call(UserHttp.deleteUser, id);

    yield put(deleteUserSuccess(id));
    yield put(alertSuccess('User deleted successfully!'));
    yield (window.location.href = `/${LINK_USERS}`);
  } catch (error) {
    yield put(deleteUserFailure());
    yield put(alertError(error));
  }
}

function* workerCreateUser(action: ICreateUserRequest) {
  try {
    const { body } = action.payload;

    const data: FullUser = yield call(UserHttp.createUser, body);

    yield put(createUserSuccess(data));
    yield put(alertSuccess('User created successfully!'));
    yield put(hideModal());
  } catch (error) {
    yield put(createUserFailure());
    yield put(alertError(error));
  }
}

function* workerGetSearchListUser(action: IGetSearchListUserRequest) {
  try {
    const { query } = action.payload;
    const data: { data: FullUser[] } = yield call(UserHttp.getListUser, query);

    yield put(getSearchListUserSuccess(data));
  } catch (error) {
    yield put(getSearchListUserFailure());
    yield put(alertError(error));
  }
}

function* workerResetUserPassword(action: IResetUserPasswordRequest) {
  try {
    const { id } = action.payload;

    yield call(UserHttp.resetUserPassword, id);

    yield put(resetUserPasswordSuccess());
    yield put(alertSuccess('A message was sent to e-mail'));
  } catch (error) {
    yield put(resetUserPasswordFailure());
    yield put(alertError(error));
  }
}

function* watchGetUserLocation() {
  yield takeEvery(GET_USER_LOCATION_REQUEST, workerGetUserLocation);
}

function* watchGetListUser() {
  yield takeEvery(GET_LIST_USER_REQUEST, workerGetListUser);
}

function* watchGetOneUser() {
  yield takeEvery(GET_ONE_USER_REQUEST, workerGetOneUser);
}

function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER_REQUEST, workerUpdateUser);
}

function* watchDeleteUser() {
  yield takeLatest(DELETE_USER_REQUEST, workerDeleteUser);
}

function* watchCreateUser() {
  yield takeLatest(CREATE_USER_REQUEST, workerCreateUser);
}

function* watchGetSearchListUser() {
  yield takeLatest(GET_SEARCH_LIST_USER_REQUEST, workerGetSearchListUser);
}

function* watchResetUserPassword() {
  yield takeLatest(RESET_USER_PASSWORD_REQUEST, workerResetUserPassword);
}

export const userWatchers: ForkEffect[] = [
  fork(watchGetListUser),
  fork(watchGetUserLocation),
  fork(watchGetOneUser),
  fork(watchUpdateUser),
  fork(watchDeleteUser),
  fork(watchCreateUser),
  fork(watchGetSearchListUser),
  fork(watchResetUserPassword),
];
