import axios, { CancelTokenSource } from 'axios';

import { FullProduct, FullStoresList } from '@entities/Product';
import { FullDriversList } from '@entities/User';
import { http } from '@utils';

const basePath = '/api/products';
let axiosSource: CancelTokenSource | null = null;

export const getList = async (
  query?: Query['query'],
  isCancel = true,
): Promise<{
  data: FullProduct[];
}> => {
  if (isCancel) {
    if (axiosSource) {
      axiosSource.cancel();
    }
    axiosSource = axios.CancelToken.source();
  }

  const { data } = await http.get<DataResponse<FullProduct[]>>(
    `${basePath}${query || ''}`,
    {
      ...(axiosSource && { cancelToken: axiosSource.token }),
    },
  );

  return data;
};

export const create = async (
  body: Pick<FullProduct, 'name' | 'description'>,
) => {
  const {
    data: { data },
  } = await http.post<DataResponse<FullProduct>>(`${basePath}`, body);

  return data;
};

export const remove = async (id: number): Promise<void> => {
  await http.delete(`${basePath}/${id}`);
};

export const getOne = async (id: FullProduct['id']): Promise<FullProduct> => {
  const {
    data: { data },
  } = await http.get<DataResponse<FullProduct>>(`${basePath}/${id}`);

  return data;
};

export const getStores = async (
  id: number,
  query?: Query['query'],
  isCancel = true,
): Promise<{
  data: FullStoresList[];
}> => {
  if (isCancel) {
    if (axiosSource) {
      axiosSource.cancel();
    }
    axiosSource = axios.CancelToken.source();
  }

  const { data } = await http.get<DataResponse<FullStoresList[]>>(
    `${basePath}/${id}/stores${query || ''}`,
    {
      ...(axiosSource && { cancelToken: axiosSource.token }),
    },
  );

  return data;
};

export const getDrivers = async (
  id: number,
  query?: Query['query'],
  isCancel = true,
): Promise<{
  data: FullDriversList[];
}> => {
  if (isCancel) {
    if (axiosSource) {
      axiosSource.cancel();
    }
    axiosSource = axios.CancelToken.source();
  }

  const { data } = await http.get<DataResponse<FullDriversList[]>>(
    `${basePath}/${id}/drivers${query || ''}`,
    {
      ...(axiosSource && { cancelToken: axiosSource.token }),
    },
  );

  return data;
};

export const update = async (
  id: number,
  body: Partial<FullProduct>,
): Promise<FullProduct> => {
  const {
    data: { data },
  } = await http.put<DataResponse<FullProduct>>(`${basePath}/${id}`, body);

  return data;
};

export const changeDrivers = async (
  id: number,
  body: {
    driverId: number;
    quantity: number;
  }[],
) => {
  await http.post<DataResponse<FullProduct>>(`${basePath}/${id}/drivers`, body);
};

export const changeStores = async (
  id: number,
  body: {
    quantity: number;
    storeId: number;
  }[],
) => {
  await http.post<DataResponse<FullProduct>>(`${basePath}/${id}/stores`, body);
};

export const deleteDriver = async (
  id: number,
  driverId: number,
): Promise<void> => {
  await http.delete(`${basePath}/${id}/drivers/${driverId}`);
};

export const deleteStore = async (
  id: number,
  storeId: number,
): Promise<void> => {
  await http.delete(`${basePath}/${id}/stores/${storeId}`);
};
