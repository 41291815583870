import { META_DEFAULT, Role } from '@utils';

import {
  CLEAR_AVATAR_STORE,
  CLEAR_FILTER,
  CLEAR_SORT,
  CLEAR_USER_LIST_STORE,
  CLEAR_USER_LOCATION,
  CLEAR_USER_ONE_STORE,
  CREATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  GET_LIST_USER_FAILURE,
  GET_LIST_USER_REQUEST,
  GET_LIST_USER_SUCCESS,
  GET_ONE_USER_FAILURE,
  GET_ONE_USER_REQUEST,
  GET_ONE_USER_SUCCESS,
  GET_SEARCH_LIST_USER_FAILURE,
  GET_SEARCH_LIST_USER_REQUEST,
  GET_SEARCH_LIST_USER_SUCCESS,
  GET_USERS_SUCCESS,
  GET_USER_LOCATION_FAILURE,
  GET_USER_LOCATION_REQUEST,
  GET_USER_LOCATION_SUCCESS,
  RESET_USER_PASSWORD_FAILURE,
  RESET_USER_PASSWORD_REQUEST,
  RESET_USER_PASSWORD_SUCCESS,
  SET_FILTER,
  SET_SORT,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from './User.constant';
import { IUserState, UserActionsTypes } from './User.type';

const initialState: IUserState = {
  userList: null,
  userCustomer: {
    list: null,
    meta: META_DEFAULT,
  },
  userDriver: {
    list: null,
    meta: META_DEFAULT,
  },
  userLocation: {
    list: null,
    meta: META_DEFAULT,
  },
  meta: META_DEFAULT,
  order: null,
  filter: null,
  user: null,
  loadingUser: false,
  loading: false,
  searchUserList: [],
  loadingSearch: false,
  isChangeList: false,
};

export default function userReducer(
  state = initialState,
  action: UserActionsTypes,
): IUserState {
  switch (action.type) {
    case GET_USER_LOCATION_REQUEST:
    case GET_LIST_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_USER_SUCCESS: {
      return {
        ...state,
        userList: [...(state.userList || []), ...action.payload.data],
        meta: action.payload.meta,
        loading: false,
        ...(action.payload?.role === Role.USER && {
          userCustomer: {
            list: [...(state.userCustomer.list || []), ...action.payload.data],
            meta: action.payload.meta,
          },
        }),
        ...(action.payload?.role === Role.DRIVERS && {
          userDriver: {
            list: [...(state.userDriver.list || []), ...action.payload.data],
            meta: action.payload.meta,
          },
        }),
      };
    }
    case GET_USERS_SUCCESS: {
      return {
        ...state,
        userList: [...action.payload.data, ...(state.userList || [])],
        ...(action.payload?.role === Role.USER && {
          userCustomer: {
            ...state.userCustomer,
            list: [...(state.userCustomer.list || []), ...action.payload.data],
          },
        }),
        ...(action.payload?.role === Role.DRIVERS && {
          userDriver: {
            ...state.userDriver,
            list: [...(state.userDriver.list || []), ...action.payload.data],
          },
        }),
        loading: false,
      };
    }
    case GET_USER_LOCATION_SUCCESS: {
      return {
        ...state,
        userLocation: {
          list: [...(state.userLocation.list || []), ...action.payload.data],
          meta: action.payload.meta,
        },
        loading: false,
      };
    }
    case GET_LIST_USER_FAILURE:
    case GET_USER_LOCATION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case SET_SORT:
      return {
        ...state,
        order: action.payload,
      };
    case SET_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case GET_ONE_USER_REQUEST:
    case UPDATE_USER_REQUEST:
    case DELETE_USER_REQUEST:
    case CREATE_USER_REQUEST:
    case RESET_USER_PASSWORD_REQUEST:
      return {
        ...state,
        loadingUser: true,
        isChangeList: false,
      };
    case GET_ONE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        loadingUser: false,
        ...(action.payload.location && {
          userLocation: {
            ...state.userLocation,
            list: [action.payload.user, ...(state.userLocation.list || [])],
          },
        }),
        ...(action.payload?.role === Role.USER && {
          userCustomer: {
            ...state.userCustomer,
            list: [action.payload.user, ...(state.userCustomer.list || [])],
          },
        }),
        ...(action.payload?.role === Role.DRIVERS && {
          userDriver: {
            ...state.userDriver,
            list: [action.payload.user, ...(state.userDriver.list || [])],
          },
        }),
      };
    case UPDATE_USER_SUCCESS: {
      const { user } = action.payload;

      return {
        ...state,
        user,
        userList: (state.userList || []).map((item) =>
          item.id === user.id ? user : item,
        ),
        loadingUser: false,
        isChangeList: true,
      };
    }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loadingUser: false,
        isChangeList: true,
        userList: (state.userList || []).filter(
          (item) => item.id !== action.payload,
        ),
      };
    case CREATE_USER_SUCCESS: {
      return {
        ...state,
        isChangeList: true,
        loadingUser: false,
        userList: [action.payload, ...(state.userList || [])],
      };
    }
    case GET_ONE_USER_FAILURE:
    case UPDATE_USER_FAILURE:
    case DELETE_USER_FAILURE:
    case CREATE_USER_FAILURE:
    case RESET_USER_PASSWORD_SUCCESS:
    case RESET_USER_PASSWORD_FAILURE:
      return {
        ...state,
        loadingUser: false,
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filter: null,
      };
    case CLEAR_SORT:
      return {
        ...state,
        order: null,
      };
    case GET_SEARCH_LIST_USER_REQUEST:
      return { ...state, loadingSearch: true };
    case GET_SEARCH_LIST_USER_SUCCESS:
      return {
        ...state,
        searchUserList: action.payload.data,
        loadingSearch: false,
      };
    case GET_SEARCH_LIST_USER_FAILURE:
      return { ...state, loadingSearch: false };
    case CLEAR_USER_LIST_STORE:
      return {
        ...state,
        ...(!action.payload && {
          userList: null,
          meta: META_DEFAULT,
          userCustomer: initialState.userCustomer,
          userDriver: initialState.userDriver,
        }),
        ...(action.payload === Role.USER && {
          userCustomer: initialState.userCustomer,
        }),
        ...(action.payload === Role.DRIVERS && {
          userDriver: initialState.userDriver,
        }),
      };
    case CLEAR_USER_LOCATION:
      return {
        ...state,
        userLocation: initialState.userLocation,
      };
    case CLEAR_USER_ONE_STORE:
      return {
        ...state,
        user: null,
      };
    case CLEAR_AVATAR_STORE:
      return {
        ...state,
        ...(state.user && {
          user: { ...state.user, avatar: null },
        }),
      };
    default:
      return state;
  }
}
